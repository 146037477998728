import React, { useEffect } from 'react';
import './fonts.css';
import './App.css';

function App() {
  useEffect(() => {
    document.title = 'Aftermath'; // Замените 'Your New Title' на желаемый заголовок
  }, []); // Пустой массив зависимостей означает, что эффект будет запущен только один раз после монтирования

  return (
    <div className="App">
      <header className="App-header">
        <img src="/aftermath-logo-vice.png" alt="AFTERMATH Logo" style={{ width: '200px', height: '200px' }} />
        <h1>Welcome to AFTERMATH</h1>
        <p>Design and future under construction by anchori, grassdor & mitchburneo</p>
        <p></p>
      </header>
    </div>
  );
}

export default App;
